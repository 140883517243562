module.exports = [{
      plugin: require('../../../themes/paws/gatsby-browser.tsx'),
      options: {"plugins":[],"BrandId":"A70172A0-2217-432F-B786-387BFB40A167","productRef":"GC5WF"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/build/repo/packages/themes/agria/src/images/icon-512x512.png","icon_options":{"purpose":"any maskable"},"name":"Agria Pet Insurance","short_name":"Agria","start_url":"/","background_color":"#02315B","theme_color":"#02315B","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1864b9fcc160722ec00366f136cbe20f"},
    },{
      plugin: require('../../../themes/agria/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/qab/gatsby-browser.tsx'),
      options: {"plugins":[],"companyName":"Agria Pet Insurance","contactNumber":"03330 30 83 98","footerTerms":"<p>The Governing Council of the Cat Fancy is an Introducer Appointed Representative of Agria Pet Insurance Ltd, who administer the insurance. Agria Pet Insurance is authorised and regulated by the Financial Conduct Authority, Financial Services Register Number 496160. Agria insurance policies are underwritten by Agria Försäkring who is authorised and regulated by the Prudential Regulatory Authority and Financial Conduct Authority.</p>\n        <p>\n        <br />\n        For Jersey customers: <br />\n        \n        Agria Pet Insurance Ltd is regulated by the Jersey Financial Services Commission (JFSC). Ref: 0001498. Registered office: As detailed above.\n        </p>\n        \n        <p>\n        <br />\n          For Guernsey customers: <br />\n        \n          Clegg Gifford Channel Islands Limited is licensed by the Guernsey Financial Services Commission. Ref: 2722221. Registered office: Admiral House, Place Du Commerce, St Peter Port, Guernsey GY1 2AT.\n        </p>","trustpilotUrl":"https://www.trustpilot.com/review/agriapet.co.uk","allowedSpecies":["Cat"],"isFreeJourney":true,"isLitter":true,"petOrAnimal":"animal"},
    },{
      plugin: require('../../../themes/qab-affiliate-club/gatsby-browser.tsx'),
      options: {"plugins":[],"affiliateType":"breeder","affiliateTypeIds":["a04b49c9-e8ee-4477-a5bd-e40a9a446183"],"heroHeading":"Activate 5 weeks free cover","heroDescription":"Give your litters the best start in their forever homes","numberWeeksFreeCover":"5"},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
